<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-end>
      <v-flex xs12 sm12 md4 text-left>
        <span class="mainHeader">PURCHASE LIST</span>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right pl-1>
        <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    background-color="white"
                    outlined color="#b234a9"
                    readonly
                    hide-details
                    clearable
          class="rounded-xl"
          dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
        </v-flex>
        <v-flex xs12 sm3 md2 text-right pl-1>
          <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    background-color="white"
                    readonly color="#b234a9"
                    outlined
                    hide-details
                    clearable
          class="rounded-xl"
          dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
        </v-flex>
      <v-flex xs12 sm3 md2 text-right pl-1>
        <v-text-field
          background-color="white"
          v-model="keyword"
          clearable
          hide-details="auto"
          color="#b234a9"
          placeholder="Search"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right pl-1>
        <v-select clearable
                      outlined
                      hide-details
                      dense
                      background-color="white"
                      class="rounded-xl"
                      color="#b234a9"
                      v-model="status"
                      :items="settledstatus"
                      label="Status"
                    ></v-select>
</v-flex>
      <v-flex xs12 sm3 md2 text-left pl-1 pt-1 class="buttons1">
        <v-btn class="buttons1" dark color="#3F053C" block @click="$router.push('/Purchase')"
          >New Purchase</v-btn
        >
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
          elevation="0"
          class="rounded-lg"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="PurchasedList">
            <v-flex xs12 v-if="PurchasedList.length>0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left tablefont"><b>S.No.</b></th>
                      <th class="text-left tablefont"><b>Purchase Date</b></th>
                      <th class="text-left tablefont"><b>Bill No</b></th>
                      <th class="text-left tablefont"><b>Supplier Name</b></th>
                      <th class="text-left tablefont"><b>Invoice No</b></th>
                      <th class="text-left tablefont"><b>Invoice Date</b></th>
                      <!-- <th class="text-left tablefont"><b>Total Amt. </b></th> -->
                      <th class="text-left tablefont"><b>Total Wt(gm) </b></th>
                      <th class="text-left tablefont"><b>Status</b></th>
                      <th class="text-left tablefont"><b>Actions</b></th>
                      <!-- <th class="text-left tablefont"><b>Role</b></th>
                      <th class="text-left tablefont"><b>Staff Code</b></th>
                      <th class="text-left tablefont"><b>Status</b></th>
                      <th class="text-left tablefont"><b>Action</b></th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in PurchasedList" :key="i" class="table" :style="value.isSettled==false ? 'background-color:#f9f6ff' : ''">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        <span v-if="value.purchaseDate">{{formatDate(value.purchaseDate)}}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.billNo">{{ value.billNo }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.supplierId">{{ value.supplierId.supplierName }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.invoiceNo">{{ value.invoiceNo }}</span>
                        <span v-else>-</span>
                      </td>

                      <td>
                        <span v-if="value.invoiceDate">{{ formatDate(value.invoiceDate) }}</span>
                        <span v-else>-</span>
                      </td>
                      <!-- <td>
                        <span v-if="value.totalPurchaseCost">{{value.totalPurchaseCost}}</span>
                        <span v-else>-</span>
                      </td> -->
                      <td>
                        <span v-if="value.totalNetWeight">{{value.totalNetWeight}}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.settleStatus=='Settled'">Settled</span>
                        <span v-else-if="value.settleStatus=='notSettled'"><v-icon small color="red">mdi-alert-circle-outline</v-icon>Not Settled</span>
                        <span v-else-if="value.settleStatus=='partiallySettled'"><v-icon small color="#ffb3b3">mdi-alert-circle-outline</v-icon>Partially Settled</span>
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                       <!-- <v-icon @click="$router.push('/PurchaseSettlement2?id='+value._id)" color="#4a1238" class="pr-2">
                        mdi-file-edit
                       </v-icon> -->
                       <v-icon  @click="$router.push('/purchaseDetails?id='+value._id)" color="#4a1238">
                        mdi-eye
                       </v-icon>
                      </td>
                      <!-- <td>
                        <span v-if="value.subRoleId">
                          {{ value.subRoleId.roles }}</span
                        >
                        <span v-else>-</span>
                      </td>
                       <td>
                        <span v-if="value.staffCode">
                          {{ value.staffCode }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <td>
                      
                        <v-btn v-if="value.staffStatus == 'Inactive'" @click="(changestatus2 = true),(statusId= value._id)" small block color="red" dark>
                          Inactive
                        </v-btn>
                        <v-btn v-if="value.staffStatus == 'Expire'" small block color="grey" dark>
                          Expired
                        </v-btn>
                        <v-btn v-if="value.staffStatus == 'Active'" @click="(changestatus = true),(statusId= value._id)" small block color="green" dark>
                          Active
                        </v-btn>
                      </td>
                      <td>
                        <v-icon
                          size="18" color="#3F053C"
                          class="pr-2" title="Edit"
                          style="cursor: pointer"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          size="18" color="#3F053C" title="Delete"
                          style="cursor: pointer"
                          @click="(deletedialog = true), (curId = value._id)"
                          >mdi-delete</v-icon
                        >
                      </td> -->
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12  v-else pa-4 align-self-center text-center class="tablefont1">
              No data found
              </v-flex>
          </v-layout>
          <v-layout wrap v-if="PurchasedList">
            <v-flex xs12 pt-4 v-if="PurchasedList.length>0">
              <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
        <v-dialog width="400px" v-model="addRole">
          <v-card width="400px">
               <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center><span style="color:#3F053C;font-size:25px;">Add Role</span></v-flex>
             <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 px-4 pt-4>
                <v-text-field
                color="#b234a9"
                  
                  label="Roles"
                  v-model="roles"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 px-4 py-4>
                <v-select
                  :items="adminList"
                  v-model="jewelleryId"
                  label="Select Jewellery"
                  item-text="jewelleryName"
                  item-value="_id"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-flex> -->
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="addRole = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="AddRole()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="800px" v-model="addStaff">
          <v-card width="800px">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center><span style="color:#3F053C;font-size:25px;">Add Staff</span></v-flex>
             
              <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs6>
                 <v-layout wrap>
              <v-flex xs12 px-4>
                <v-text-field
                color="#b234a9"
                  
                  label="Name"
                  v-model="username"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pt-4>
                <v-text-field
                color="#b234a9"
                  
                  label="Staff Code"
                  v-model="staffCode"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pt-4 text-left>
                <v-select
                  :items="rolesList"
                  v-model="roleId"
                  label="Role"
                  item-text="roles"
                  item-value="_id"
                  outlined
                  hide-details
                  dense
                ></v-select>
                
              </v-flex>
              <v-flex xs4 px-4 pt-1 text-center align-self-center>
              <v-chip small outlined tile color="#3F053C" @click="addRole = true">
             <v-icon small>mdi-plus</v-icon>   Add Roles
              </v-chip>
            </v-flex>
            </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap>
                    <v-flex xs12 px-4 pb-4>
                <v-text-field
                color="#b234a9"
                  v-model="phone"
                  dense
                  label="Phone"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pb-4>
                <v-text-field
                color="#b234a9"
                  v-model="email"
                  dense
                  label="Email"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
           
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="addStaff = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="AddStaff()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
             <v-dialog width="600px" v-model="editdialogue">
          <v-card width="600px">
           <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs12 pt-4 text-center><span style="color:#3F053C;font-size:25px;">Edit Staff</span></v-flex>
             <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs6>
                <v-layout wrap>
              <v-flex xs12 px-4>
                <v-text-field
                color="#b234a9"
                  
                  label="Name"
                  v-model="curItem.username"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 py-4>
                <v-select
                color="#b234a9"
                  :items="rolesList"
                  v-model="curItem.mainRole"
                  label="Role"
                  item-text="roles"
                  item-value="_id"
                  outlined
                  hide-details
                  dense
                ></v-select>
              </v-flex>
</v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap>
              <v-flex xs12 px-4 pb-4>
                <v-text-field
                color="#b234a9"
                  v-model="curItem.phone"
                  dense
                  label="Phone"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 px-4 pb-4>
                <v-text-field
                color="#b234a9"
                  v-model="curItem.email"
                  dense
                  label="Email"
                  outlined
                  hide-details
                ></v-text-field>
              </v-flex>
            </v-layout>
              </v-flex>
            </v-layout>


            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                 color="#3F053C"
                class="body-2 font-weight-bold"
                dark
                @click="editStaff()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="300px" v-model="changestatus">
        <v-card width="300px">
         
          <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs11 pt-4 text-center><span style="color:#3F053C;font-size:25px;">Change Status</span></v-flex>
              <v-flex xs1> <v-icon
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="changestatus = false"
              >mdi-close</v-icon
            ></v-flex>
             <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
            </v-layout>
          <v-card-text class="pa-4"
            >Do You wish to Disable <br/>
this Staff Member?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-layout wrap justify-center>
              <v-flex xs9>
                <v-btn
                color="#690964"
              class="body-2 font-weight-bold"
              dark
              @click="changestaffstatus()"
              >YES</v-btn
            >
                <v-btn
                color="#3F053C"
              dark
              class="body-2 font-weight-bold ml-2"
              @click="changestatus = false"
              >NO</v-btn
            >
          
              </v-flex>
            </v-layout>
           
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="300px" v-model="changestatus2">
        <v-card width="300px">
         
          <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs11 pt-4 text-center><span style="color:#3F053C;font-size:25px;">Change Status</span></v-flex>
              <v-flex xs1> <v-icon
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="changestatus2 = false"
              >mdi-close</v-icon
            ></v-flex>
             <v-flex xs8 text-center py-4>
              <v-divider></v-divider>
            </v-flex>
            </v-layout>
          <v-card-text class="pa-4"
            >Activate this Staff Member
 <br/>
 Back?
          </v-card-text>
          <v-card-actions class="pt-3">
            <v-layout wrap justify-center>
              <v-flex xs9>
                <v-btn
                color="#690964"
              class="body-2 font-weight-bold"
              dark
              @click="changestaffstatus2()"
              >YES</v-btn
            >
                <v-btn
                color="#3F053C"
              dark
              class="body-2 font-weight-bold ml-2"
              @click="changestatus = false"
              >NO</v-btn
            >
          
              </v-flex>
            </v-layout>
           
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog width="300px" v-model="deletedialog">
        <v-card width="300px">
         
          <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs11 pt-4 text-center><span style="color:#3F053C;font-size:25px;">Delete</span></v-flex>
              <v-flex xs1> <v-icon
              color="grey"
              text
              class="body-2 font-weight-bold"
              @click="deletedialog = false"
              >mdi-close</v-icon
            ></v-flex>
             <v-flex xs8 text-center pt-4>
              <v-divider></v-divider>
            </v-flex>
            </v-layout>
          <v-card-text class="pa-4"
            >Make this Staff Expired<br/>
 or<br/>
 Delete staff from the List 
          </v-card-text>
          <v-card-actions>
            <v-layout wrap justify-center>
              <v-flex xs9>
                <v-btn
               color="#690964"
              class="body-2 font-weight-bold"
              dark
              @click="deleteStaff()"
              >Delete</v-btn
            >
            <v-btn
               color="#3F053C"
              class="body-2 font-weight-bold ml-2"
              dark
              @click="expireStaff()"
              >EXPIRED</v-btn
            >
              </v-flex>
            </v-layout>
           
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      page:1,
      menu: false,
      menu2: false,
      GstStat:"NOGST",
      Pagelength:null,
      statusId:null,
      staffstatus:"Active",
      addRole: false,
      roles: null,
      jewelleryId: null,
      adminList: [],
      rolesList: [],
      username: null,
      staffCode: null,
      roleId: null,
      phone: null,
      email: null,
      addStaff: false,
      positionList: null,
      PurchasedList:[],
      curItem:[],
      curId:null,
       editdialogue:false,
deletedialog:false,
changestatus:false,
changestatus2:false,
keyword:null,
fromDate:"",
//  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
//         .toISOString()
//         .substr(0, 10),
      toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        settledstatus: ["Settled","Not Settled","Partially Settled"],
        settlestatus:"",
        status:"",
    };
  },
  watch:{
    page(){
      this.getData();
    },
    keyword(){
    this.getData();
    },
    fromDate() {
      if (this.page > 1) this.page = 1;
      this.getData();
    },
    toDate() {
      if (this.page > 1) this.page = 1;

      this.getData();
    },
    status() {
      if(this.status=="Settled"){
        this.settlestatus="Settled";
      this.getData();
      }
      else if(this.status=="Not Settled"){
        this.settlestatus="notSettled";
      this.getData();
      }
      else if(this.status=="Partially Settled"){
        this.settlestatus="partiallySettled";
      this.getData();
      }
      else{
        this.settlestatus="";
      this.getData();
      }
    },
  },
  mounted() {

    this.getData();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
    // changeGST(){
    //   this.GstStat="GST"
    //   console.log("gststst=",this.GstStat)
    //   this.getData();
    // },
    // changeGST2(){
    //   this.GstStat="NOGST"
    //   console.log("gststst=",this.GstStat)
    //   this.getData();
    // },
    getData() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/purchaseitem/getalllist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          limit: 20,
          keyword:this.keyword,
          from: this.fromDate,
          to: this.toDate,
          settlestatus:this.settlestatus,
          // taxType: this.GstStat,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.PurchasedList = response.data.data;
            this.Pagelength = Math.ceil(response.data.totalLength / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getRoless() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/getalllist/roles",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.appUser._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.rolesList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    AddRole() {
      axios({
        url: "/add/roles",
        method: "POST",
        data: {
          roles: this.roles,
          jewelleryId: this.jewelleryId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.addRole = false;
            this.jewelleryId = null;
            this.roles = null;
            this.getRoless();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    AddStaff() {
      axios({
        url: "/user/add",
        method: "POST",
        data: {
          roleId: this.roleId,
          firstname: this.username,
          email: this.email,
          phone: this.phone,
          staffCode:this.staffCode,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.addStaff = false;
            this.phone = null;
            this.roleId = null;
            this.email = null;
            this.username = null;
            this.staffCode = null;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editStaff(){
      axios({
        url: "/user/adminedit",
        method: "POST",
        headers: {
         "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id:this.curItem._id,
          subRoleId: this.curItem.subRoleId,
          username: this.curItem.username,
          email: this.curItem.email,
          phone: this.curItem.phone,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
      deleteStaff() {
      axios({
        url: "/user/remove",
        method: "GET",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    expireStaff() {
      axios({
        url: "/user/expire",
        method: "GET",
        headers: {
            "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status==true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changestaffstatus() {
      axios({
        url: "/change/status",
        method: "POST",
        data: {
          id: this.statusId,
          status: "Inactive",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.changestatus = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changestaffstatus2() {
      axios({
        url: "/change/status",
        method: "POST",
        data: {
          id: this.statusId,
          status: "Active",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.changestatus2 = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year
        //  +
        // " , " +
        // hours +
        // ":" +
        // minutes +
        // " " +
        // ampm;

      return strTime;
    },
  },
};
</script>
